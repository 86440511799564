const RECEIVE_STATUS = {
  FULLY: { key: 1, label: 'received' },
  PARTIALLY: { key: 2, label: 'partially' },
  NONE: { key: 3, label: 'none' },
  OVERLY: { key: 4, label: 'overly' },
};

/**
 * Checks the date is valid
 * @param {Date} date
 * @returns {boolean}
 */
const isValidDate = (date) => {
  // Check existance of date parameter
  if (!date) return false;

  // Check data type of parameter
  if (typeof date !== 'object') return false;

  // Check getTime function
  if (typeof date.getTime !== 'function') return false;

  return !isNaN(date.getTime());
};

/**
 * Get date on mm/dd/yyyy format
 * returns null if the parameter is not a date
 * @param {Date} date
 * @returns {String|null}
 */
export const getFormattedDate = (date, shouldShowTime = false) => {
  if (!isValidDate(date)) return null;

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: undefined,
    minute: undefined,
  };

  if (shouldShowTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const getDateWithoutOffset = (date) => {
  const originalDate = new Date(date);
  const originalTimestamp = originalDate.getTime();
  const originalOffset = originalDate.getTimezoneOffset();
  const newOffset = 0;
  const offsetDifference = newOffset - originalOffset;
  const newTimeStamp = originalTimestamp + offsetDifference * 60 * 1000;
  const newDate = new Date(newTimeStamp);
  return newDate.toISOString();
};
export const getTotalItemQuantity = (pallet, box, unit) => {
  const palletQuantity =
    pallet && pallet > 0 ? parseFloat(pallet.replaceAll(',', '')) : 1;
  const boxQuantity = box && box > 0 ? parseFloat(box.replaceAll(',', '')) : 1;
  const itemQuantity =
    unit && unit > 0 ? parseFloat(unit.replaceAll(',', '')) : 1;

  if (!pallet && !box && !unit) return 0;
  return palletQuantity * boxQuantity * itemQuantity;
};

/**
 * Creates a new date based on parameters
 * @param {Object} obj
 * @param {String} obj.day
 * @param {String} obj.month
 * @param {String} obj.year
 * @returns {Date|null} returns date or null if date is invalid
 */
const parsedDateStringsToDate = ({ day, month, year }) => {
  // Check parameters lenght
  if (
    day.length > 2 ||
    day.length === 0 ||
    month.length > 2 ||
    month.length === 0 ||
    year.length < 4 ||
    year.length === 0
  )
    return null;

  let monthTxt = month.length < 2 ? `0${month}` : month;
  let dayTxt = day.length < 2 ? `0${day}` : day;

  const date = new Date(`${year}-${monthTxt}-${dayTxt}`);

  return isValidDate(date) ? date : null;
};

/**
 * Extracts day, month and year from given string and
 * calls parsedDateStringsToDate function
 * @param {String} arg
 * @returns {Function} parsedDateStringsToDate
 */
const nonSlashedStringToDate = (arg) => {
  if (arg.length !== 8) return null;

  const month = arg.substring(0, 2);
  const day = arg.substring(2, 4);
  const year = arg.substring(4);

  return parsedDateStringsToDate({ year, month, day });
};

/**
 * Converts string to date according how it was written
 * @param {String} value
 * @returns
 */
export const stringToDate = (value) => {
  let result = null;

  if (typeof value !== 'string') return result;

  const args = value.split('/');

  switch (args.length) {
    case 1:
      result = nonSlashedStringToDate(args[0]);
      break;
    case 3:
      result = parsedDateStringsToDate({
        year: args[2],
        month: args[0],
        day: args[1],
      });
      break;
  }

  return result;
};

/**
 * Set current hours to the date
 * @param {Date} date
 * @returns {Date}
 */
export const setCurrentHourToDate = (date) => {
  const currentDate = new Date();
  date.setHours(currentDate.getHours(), currentDate.getMinutes());

  return date;
};

/**
 * Calculates difference of days between two dates
 * @param {Date} date1
 * @param {Date} date2
 * @returns {boolean}
 */
export const calculateDayDifference = (date1, date2) => {
  if (!isValidDate(date1) || !isValidDate(date2)) return null;

  return (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24);
};

/**
 * Gets color class according to fba status and expiration date
 *
 * **NOTE**, since we re no longer getting isFBA info from backend,
 * isFba parameter is set *true* as default
 * @param {Date|null} exiprationDate
 * @param {Boolean|undefined} isFba
 * @returns
 */
export const expirationDateColor = (exiprationDate, isFba = true) => {
  if (!exiprationDate) return '';

  const today = new Date();

  if (exiprationDate.getTime() <= today.getTime()) return 'text-danger';

  if (!isFba) return '';

  if (calculateDayDifference(exiprationDate, today) < 105)
    return 'text-warning';

  return '';
};

/**
 * Returns receive status class text
 * @param {Number} receivableQty
 * @param {Number} receivedQty
 * @returns {String}
 */
export const getItemStatusByQuantities = (
  receivableQty,
  receivedQty,
  orderItemQuantity = null
) => {
  receivableQty = parseFloat(receivableQty);
  receivedQty = parseFloat(receivedQty);

  if (receivableQty < receivedQty) {
    return RECEIVE_STATUS.OVERLY.label;
  }

  if (receivableQty === receivedQty) {
    return RECEIVE_STATUS.FULLY.label;
  }

  if (receivedQty === 0) {
    if (orderItemQuantity === null || receivableQty === orderItemQuantity) {
      return RECEIVE_STATUS.NONE.label;
    }
  }

  return RECEIVE_STATUS.PARTIALLY.label;
};

/**
 * Return receive status class text by status enum
 * @param {Number} statusEnum
 * @returns {String}
 */
export const getItemStatusByEnum = (statusEnum) => {
  for (const status in RECEIVE_STATUS) {
    if (RECEIVE_STATUS[status].key === statusEnum)
      return RECEIVE_STATUS[status].label;
  }

  return null;
};

/**
 * Finds the item on list
 * @param {any} value identical value to be searched on list
 * @param {string} key identical property name of list
 * @param {Object[]} list the array to be searched
 * @returns {Object|null} result
 */
export const findSelectedItem = (value, key, list) => {
  return (
    list.find((item) => {
      let data1 = item[key];
      let data2 = value;

      // Check types
      if (typeof data1 !== typeof data2) return null;

      // Set texts to lower case if types are string
      if (typeof data === 'string') {
        data1 = data1.toLowerCase();
        data2 = data2.toLowerCase();
      }

      return data1 === data2;
    }) || null
  );
};

export const downloadURI = (uri, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFloatedNumber = (value, number) => {
  if (Number.isNaN(value)) return '-';
  if (value < 0) {
    value = Math.abs(value);
  }
  const precisionNumber = Math.pow(10, number);
  return (Math.floor(value * precisionNumber) / precisionNumber).toFixed(
    number
  );
};

export const thousandFormatter = (
  number,
  currency = null,
  decimal = true,
  thousandSeparator = ',',
  hasPercentage = false
) => {
  if (number == null || Number.isNaN(number)) return '-';
  if (decimal) {
    number = parseFloat(number).toFixed(2);
  } else {
    number = number.toString();
  }
  const pricePattern = /(\d)(?=(\d{3})+(?!\d))/g;
  const repl = `$1${thousandSeparator}`;
  let percentage = hasPercentage ? '%' : '';
  let intPart = number.split('.')[0];
  let intPartFormat = intPart.toString().replace(pricePattern, repl);
  let value2Array = number.split('.');
  if (currency != null && decimal) {
    return currency + intPartFormat + '.' + value2Array[1];
  } else if (decimal || hasPercentage) {
    return intPartFormat + '.' + value2Array[1] + percentage;
  } else {
    return intPartFormat;
  }
};

export const shortenString = (str, maxLen = 50, extra = '...') => {
  if (!str) return '';
  if (str.length <= maxLen) return str;

  return str.substring(0, maxLen) + extra;
};

export const notAssigned = (value) => {
  return (
    value === null ||
    value === 'undefined' ||
    value === undefined ||
    value === 'null'
  );
};

export const assigned = (value) => {
  return !notAssigned(value);
};
