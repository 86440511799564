export default {
  /**
   * Returns selected store
   * @param {Object} state - form management module state
   * @returns {Object} - selected store
   */
  selectedStore: (state) => {
    const selectedStoreName =
      localStorage.getItem('store') &&
      localStorage.getItem('store') != 'undefined'
        ? JSON.parse(localStorage.getItem('store'))
        : null;
    return state.selectedStore || selectedStoreName || {};
  },

  /**
   * Returns store tenant id
   * @param {Object} state - form management module state
   * @returns {String} - tenant id of store
   */
  tenantId: (state) => {
    if (state.selectedStore === null) return null;

    return state.selectedStore.tenantId || null;
  },

  selectedWarehouse: (state) => {
    return state.selectedWarehouse;
  },

  /**
   * Returns stores list
   * @param {Object} state - form management module state
   * @returns {Array} - stores list
   */
  stores: (state) => {
    return state.storeList.list;
  },

  /**
   * Returns lookup values
   * @param {Object} state - form management module state
   * @returns {Object} lookup lists
   */
  lookupValues: (state) => {
    return state.lookupValues.lists;
  },

  /**
   * Returns joint statuses of both store and lookup request statuses
   * @param {Object} state - form management module state
   * @returns {String} joint status
   */
  requestStatuses: (state) => {
    return {
      storeStatus: state.storeList.status,
      lookupStatus: state.lookupValues.status,
    };
  },
  userStores: (state) => {
    return state.userStores;
  },
};
