/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

import moduleAuth from './auth/moduleAuth.js';
import moduleECommerce from './eCommerce/moduleECommerce.js';
import moduleFormManagement from './form-management/moduleFormManagement.js';
import moduleExport from './export/moduleExport.js';
import modulePageRedirect from './pageRedirect/modulePageRedirect.js';
import moduleProduct from './product/moduleProduct.js';
import moduleRenewToken from './renewToken/moduleRenewToken';

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    eCommerce: moduleECommerce,
    formManagement: moduleFormManagement,
    export: moduleExport,
    pageRedirect: modulePageRedirect,
    product: moduleProduct,
    renewToken: moduleRenewToken,
  },
  strict: false,
});
