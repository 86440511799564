export default {
  exportJobs: (state) => {
    return state.exportJobs;
  },
  exportJobStatuses: (state) => {
    return state.exportJobs.map(({ status, message, name }) => ({
      status,
      message,
      name,
    }));
  },
};
