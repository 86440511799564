import state from './modulePageRedirectState.js';
import mutations from './modulePageRedirectMutations.js';
import actions from './modulePageRedirectActions.js';
import getters from './modulePageRedirectGetters.js';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
