"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Prompt',
    props: {
        active: { type: Boolean },
        title: { default: 'Are you sure?' },
        message: { default: 'This action cannot be undone.' },
        hasCancelButton: { type: Boolean, default: true },
        hasConfirmButton: { type: Boolean, default: true },
        cancelButtonText: { default: 'Cancel' },
        confirmButtonText: { default: 'Confirm' },
        confirmButtonColor: { default: 'primary' },
        confirmButtonIcon: { default: null },
        confirmButtonIconColor: { default: 'white' },
        cancelButtonColor: { default: 'secondary' },
        icon: { default: 'info' },
        minWidth: { default: 'auto' },
        maxWidth: { default: 'auto' },
        animated: { type: Boolean, default: true },
        animationDuration: { default: 300 },
        animationType: { default: 'fade' },
        closeable: { type: Boolean, default: true },
        closeOnClickOutside: { type: Boolean, default: true },
        background: null,
        positionBottom: null,
        positionChanged: { type: Boolean, default: false }
    },
    emits: ["cancel", "confirm"],
    setup(__props, { emit: emits }) {
        const props = __props;
        const store = (0, vue_2.inject)('store');
        const activeRef = (0, vue_2.ref)(props.active);
        const isVerticalNavMenuReduced = (0, vue_2.computed)(() => {
            return (store.state.verticalNavMenuWidth === 'default' && props.positionChanged);
        });
        (0, vue_2.watch)(() => props.active, (val) => {
            activeRef.value = val;
        });
        const outsideRef = (0, vue_2.ref)(null);
        const animatedValue = (0, vue_2.ref)(1);
        const cancel = () => {
            if (!props.closeable)
                return;
            animatedValue.value = 0;
            setTimeout(() => {
                activeRef.value = false;
                emits('cancel');
            }, props.animated ? props.animationDuration : 0);
        };
        const confirm = () => {
            animatedValue.value = 0;
            emits('confirm');
            setTimeout(() => {
                activeRef.value = false;
            }, props.animated ? props.animationDuration : 0);
        };
        const { default: defaultSlot, footer } = (0, vue_2.useSlots)();
        const EvaluateTypes = {
            getColorClass: (color) => {
                switch (color) {
                    case 'primary':
                        return 'bg-blue-500 text-white';
                    case 'secondary':
                        return 'bg-gray-200 text-black';
                    case 'danger':
                        return 'bg-red-500 text-white';
                    default:
                        return '';
                }
            },
            getIcon: (icon) => {
                switch (icon) {
                    case 'info':
                        return 'InfoIcon';
                    case 'warning':
                        return 'AlertTriangleIcon';
                    case 'error':
                        return 'XCircleIcon';
                    case 'success':
                        return 'CheckCircleIcon';
                    case 'external':
                        return 'ExternalLinkIcon';
                    default:
                        return '';
                }
            },
            getIconClass: (icon) => {
                switch (icon) {
                    case 'info':
                        return 'text-blue-500';
                    case 'warning':
                        return 'text-yellow-500';
                    case 'error':
                        return 'text-red-500';
                    case 'success':
                        return 'text-green-500';
                    default:
                        return '';
                }
            },
        };
        const setBodyHeight = () => {
            let body = document.body;
            if (activeRef.value) {
                animatedValue.value = 1;
                body.style.height = '100vh';
                body.style.overflow = 'hidden';
            }
            else {
                body.style.height = 'auto';
                body.style.overflow = 'auto';
            }
        };
        (0, vue_2.watch)(activeRef, setBodyHeight);
        setBodyHeight();
        const handleClickOutside = (e) => {
            if (e.target === outsideRef.value && props.closeOnClickOutside)
                cancel();
        };
        return { __sfc: true, props, store, activeRef, isVerticalNavMenuReduced, outsideRef, animatedValue, emits, cancel, confirm, defaultSlot, footer, EvaluateTypes, setBodyHeight, handleClickOutside };
    }
});
