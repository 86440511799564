export default {
  /**
   * Handles saving sku syncing api call status
   * @param {Object} state - product module states
   * @param {Object} [obj] - payload
   * @param {String} [obj.status=IDLE] - sku syncing call status
   * @param {String} [obj.message] - sku syncing api call message
   */
  SET_SKU_SYNCING_REQUEST_STATUS: (
    state,
    { status = 'IDLE', message = null } = { status: 'IDLE', message: null }
  ) => {
    state.skuSyncingRequest.status = status;
    state.skuSyncingRequest.message = message;
  },
  SET_SKU_SYNCING_STORE: (state, store = null) => {
    state.skuSyncingRequest.store = store;
  },
  SET_SKU_SYNCING_STATUS: (
    state,
    { status = 'IDLE', message = null } = { status: 'IDLE', message: null }
  ) => {
    state.skuSyncing.status = status;
    state.skuSyncing.message = message;
  },
  SET_SKU_IMAGE_SYNCING_REQUEST_STATUS: (
    state,
    { status = 'IDLE', message = null } = { status: 'IDLE', message: null }
  ) => {
    state.skuImageSyncingRequest.status = status;
    state.skuImageSyncingRequest.message = message;
  },
  SET_SKU_IMAGE_SYNCING_STORE: (state, store = null) => {
    state.skuImageSyncingRequest.store = store;
  },
  SET_SKU_IMAGE_SYNCING_STATUS: (
    state,
    { status = 'IDLE', message = null } = { status: 'IDLE', message: null }
  ) => {
    state.skuImageSyncing.status = status;
    state.skuImageSyncing.message = message;
  },
};
