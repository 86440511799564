import { render, staticRenderFns } from "./Prompt.vue?vue&type=template&id=28abd925&scoped=true&"
import script from "./Prompt.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Prompt.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Prompt.vue?vue&type=style&index=0&id=28abd925&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28abd925",
  null
  
)

export default component.exports