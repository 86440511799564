export default {
  skuSyncing: {
    status: 'IDLE',
    message: null,
  },
  skuSyncingRequest: {
    store: null,
    status: 'IDLE',
    message: null,
  },
  skuImageSyncing: {
    status: 'IDLE',
    message: null,
  },
  skuImageSyncingRequest: {
    store: null,
    status: 'IDLE',
    message: null,
  },
};
