/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js';
import axios from '../../axios';

import router from '@/router';

export default {
  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
    };

    dispatch('login', newPayload);
  },
  login({ state }, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation();

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      });

      return false;
    }
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        const newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit('UPDATE_USER_INFO', newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || '/dashboard');
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      });
  },

  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then(async (response) => {
          const responseData = response.data.Data;

          const apiStatus = response.data.ApiStatus;
          const apiStatusCode = response.data.ApiStatusCode;
          const ApiStatusMessage = response.data.ApiStatusMessage;

          if (apiStatus === false) {
            if (apiStatusCode == 404) {
              reject({
                message: 'Email is not registered. Start Eva Free now.',
              });
            } else if (apiStatusCode == 401) {
              reject({
                message: 'Your password is invalid. Please try again.',
              });
            } else if (apiStatusCode == 403) {
              reject({
                message:
                  'Email is not verified. Please check your mailbox to verify.',
              });
            } else {
              reject(ApiStatusMessage);
            }
          } else {
            if (responseData.AccessToken) {
              commit('SET_BEARER', responseData.AccessToken);
              localStorage.setItem('accessToken', responseData.AccessToken);
              const baseURL = process.env.VUE_APP_SERVICE_URL;
              try {
                const userInfoResponse = await axios.post(
                  `${baseURL}user/user-information`,
                  {
                    email: payload.userDetails.email,
                  }
                );
                const {
                  ApiStatus,
                  ApiStatusMessage,
                  Data: userInfoResponseData,
                } = userInfoResponse.data;
                if (!ApiStatus) {
                  reject(ApiStatusMessage);
                }
                if (userInfoResponseData.user) {
                  userInfoResponseData.user.store = [];
                  localStorage.setItem(
                    'isAdmin',
                    userInfoResponseData.user?.isSuperAdmin ||
                      userInfoResponseData.user?.isWarehouseAdmin ||
                      userInfoResponseData.user?.isStoreAdmin
                  );
                  localStorage.setItem(
                    'isSuperAdmin',
                    userInfoResponseData.user?.isSuperAdmin ||
                      userInfoResponseData.user?.isWarehouseAdmin
                  );
                  localStorage.setItem(
                    'userInfo',
                    JSON.stringify(userInfoResponseData.user)
                  );
                  const firstScreenPath =
                    userInfoResponseData.user?.isSuperAdmin ||
                    userInfoResponseData.user?.isWarehouseAdmin
                      ? '/dashboard'
                      : '/';
                  router.push(router.currentRoute.query.to || firstScreenPath);
                  commit('UPDATE_USER_INFO', userInfoResponseData, {
                    root: true,
                  });
                  resolve(response);
                } else {
                  reject({ message: 'Failed to fetch user info...' });
                }
              } catch (error) {
                console.log(error);
                reject({ message: 'Something went wrong, please try again' });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject({ message: 'Something went wrong, please try again' });
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    const { displayName, email, password, confirmPassword } =
      payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      jwt
        .registerUser(displayName, email, password)
        .then((response) => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/dashboard');

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken);
          commit('UPDATE_USER_INFO', response.data.userData, { root: true });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        resolve(response);
      });
    });
  },
};