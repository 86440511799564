import axios from 'axios';

const baseURL = process.env.VUE_APP_SERVICE_URL;

export default {
  bearer: {
    post(params, urlDetail, canceler) {
      if (params !== null) {
        return axios({
          method: 'post',
          mode: 'no-cors',
          url: baseURL + urlDetail,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
              'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Expose-Headers': '*',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          data: params,
          cancelToken: canceler,
        }).catch((e) => {
          if (401 === e.response.status) {
            if (localStorage.getItem('accessToken')) {
              const urlDetail = 'user/logout';

              axios({
                method: 'post',
                mode: 'no-cors',
                url: baseURL + urlDetail,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': '*',
                  'Access-Control-Allow-Credentials': 'true',
                  'Access-Control-Expose-Headers': '*',
                  Authorization: `Bearer ${localStorage.getItem(
                    'accessToken'
                  )}`,
                },
                cancelToken: canceler,
              })
                .catch(function (_error) {})
                .then(function () {
                  // always executed
                });

              localStorage.removeItem('accessToken');
              localStorage.removeItem('userInfo');
              localStorage.removeItem('isAdmin');
              window.location = `${window.location.origin}/login`;
              this.$store.dispatch('emptyUserInfo');
            }
          }

          throw e;
        });
      } else {
        return null;
      }
    },
  },
  notBearer: {
    post(params, urlDetail) {
      const obj = params;

      return axios({
        url: baseURL + urlDetail,
        method: 'post',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
          'Access-Control-Allow-Methods': 'POST',
        },
        data: obj,
      });
    },
  },
};
