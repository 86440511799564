export default {
  selectedStore: null,
  selectedWarehouse: null,
  storeList: {
    list: [],
    status: 'IDLE',
    message: null,
  },
  userStores: {
    list: [],
  },
  lookupValues: {
    lists: {
      warehouseList: [],
      shippingMethodList: [],
      carrierList: [],
      packageTypeList: [],
      itemConditionList: [],
      poIdentifierTypeList: [],
      productMappingTypeList: [],
      inventoryStatusTypeList: [],
      invoiceCategoryList: [],
      invoiceFrequencyList: [],
      invoiceTypeList: [],
      transactionTypesList: [],
      skuTypesList: [],
    },
    status: 'IDLE',
    message: null,
  },
};
