var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.activeRef)?_c('div',{staticClass:"w-screen absolute flex h-screen justify-center items-center z-[9999999] top-0",class:[
    _setup.isVerticalNavMenuReduced
      ? 'right-[120px] left-[-240px]'
      : 'right-0 left-0',
  ],on:{"click":_setup.handleClickOutside}},[_c('div',{ref:"outsideRef",staticClass:"absolute w-full bg-black opacity-20",class:[parseFloat(_vm.positionBottom) < 0 ? 'h-[140vh]' : 'h-screen']}),_c('div',{staticClass:"z-[99999999] transition-all",style:([
      _vm.animationType === 'fade'
        ? { opacity: _setup.animatedValue }
        : { transform: `scale(${_setup.animatedValue})` },
      { transitionDuration: `${_vm.animationDuration}ms !important` },
      { background: _setup.props.background },
      { marginBottom: `${_setup.props.positionBottom}px` },
    ])},[(_setup.defaultSlot)?[_vm._t("default"),(_setup.footer)?_vm._t("footer"):_vm._e()]:[_c('div',{staticClass:"bg-white flex flex-col p-6 gap-4 rounded",style:({
          minWidth: _setup.props.minWidth,
          maxWidth: _setup.props.maxWidth,
        })},[_c('div',{staticClass:"flex gap-2"},[_c('feather-icon',{staticClass:"w-6 h-6",class:_setup.EvaluateTypes.getIconClass(_setup.props.icon),attrs:{"icon":_setup.EvaluateTypes.getIcon(_setup.props.icon)}}),_c('span',{staticClass:"text-2xl text-gray-800 font-semibold"},[_vm._v(_vm._s(_setup.props.title))])],1),_c('div',[_c('p',{staticClass:"text-lg text-gray-600 font-semibold"},[_vm._v(" "+_vm._s(_setup.props.message)+" ")])]),(_setup.props.hasCancelButton || _setup.props.hasConfirmButton)?_c('div',{staticClass:"flex justify-end border-t border-gray-200 pt-2 gap-2"},[(_setup.props.hasCancelButton)?_c('button',{staticClass:"c-btn",class:_setup.EvaluateTypes.getColorClass(_setup.props.cancelButtonColor),on:{"click":_setup.cancel}},[_vm._v(" "+_vm._s(_setup.props.cancelButtonText)+" ")]):_vm._e(),(_setup.props.hasConfirmButton)?_c('button',{staticClass:"c-btn flex justify-center gap-x-2",class:_setup.EvaluateTypes.getColorClass(_setup.props.confirmButtonColor),on:{"click":_setup.confirm}},[_c('p',[_vm._v(_vm._s(_setup.props.confirmButtonText))]),(_vm.confirmButtonIcon)?_c('feather-icon',{staticClass:"w-4 h-4",class:_setup.EvaluateTypes.getColorClass(_setup.props.confirmButtonIconColor),attrs:{"icon":_setup.EvaluateTypes.getIcon(_setup.props.confirmButtonIcon)}}):_vm._e()],1):_vm._e()]):_vm._e()])]],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }