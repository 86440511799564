export default {
  skuSyncingStore: (state) => {
    return state.skuSyncingRequest.store;
  },
  skuSyncingStatus: (state) => {
    return state.skuSyncing;
  },
  skuSyncingRequestStatus: (state) => {
    return state.skuSyncingRequest;
  },
  skuImageSyncingStore: (state) => {
    return state.skuImageSyncingRequest.store;
  },
  skuImageSyncingStatus: (state) => {
    return state.skuImageSyncing;
  },
  skuImageSyncingRequestStatus: (state) => {
    return state.skuImageSyncingRequest;
  },
  skuImageUploadingStatus: (state) => {
    return state.skuImageUploadingStatus;
  },
};
