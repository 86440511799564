<template>
  <div
    class="fixed bottom-12 left-1/4 right-1/4 p-2"
    style="z-index: 99999999999999; background: #e2b203"
    v-show="displayBanner"
  >
    <div class="flex justify-end items-start" @click="closeBanner">
      <FeatherIcon
        class="text-black cursor-pointer"
        icon="XIcon"
        svgClasses="h-5 w-5"
      />
    </div>
    <div class="flex items-center pl-5 pr-10 py-4 gap-x-4 -mt-5">
      <FeatherIcon icon="AlertTriangleIcon" class="text-black" />
      <div>
        <h4 class="text-black text-justify">
          <strong>Scheduled Maintenance Notice</strong>
        </h4>
        <p class="text-black text-justify">
          Dear users, <br />
          We have scheduled essential maintenance on the Eva Platform. <br />
          <strong>Maintenance Schedule (PDT):</strong> <br />
        </p>
        <p class="text-black text-justify">
          The Eva App will be unavailable for approximately 3 hours during this
          period. We apologize for any inconvenience and appreciate your
          patience.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FeatherIcon from '@/components/FeatherIcon';
export default {
  name: 'MaintenanceBanner',
  components: {
    FeatherIcon,
  },
  methods: {
    closeBanner() {
      this.$store.dispatch('updateDisplayBanner', true);
    },
  },
  computed: {
    displayBanner() {
      return this.$store.getters['showBanner'];
    },
    // DON'T FORGOT This is EU update !!!!!
    // regionCode() {
    //   return this.$store.getters.selectedStore?.region;
    // },
  },
};
</script>
