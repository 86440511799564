/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList';
import themeConfig, { colors } from '@/../themeConfig.js';

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
const userDefaults = {
  //uid         : 0,          // From Auth
  //displayName : 'John Doe', // From Auth
  //about       : 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
  //photoURL    : require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  //status      : 'online',
  //userRole    : 'admin'
};

const trialDefaults = {
  //uid         : 0,          // From Auth
  //displayName : 'John Doe', // From Auth
  //about       : 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
  //photoURL    : require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  //status      : 'online',
  //userRole    : 'admin'
};

const fundingDefaults = {
  //uid         : 0,          // From Auth
  //displayName : 'John Doe', // From Auth
  //about       : 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
  //photoURL    : require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  //status      : 'online',
  //userRole    : 'admin'
};

const downloadDefaults = {
  status: '',
};

const uploadDefaults = {
  status: '',
};

const exportDefaults = {
  status: '',
};

const userInfoLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
  const userInfo = {};

  // Update property in user
  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key]
      ? userInfoLocalStorage[key]
      : userDefaults[key];
  });

  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach((key) => {
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null)
      userInfo[key] = userInfoLocalStorage[key];
  });

  return userInfo;
};

const trialInfoLocalStorage = JSON.parse(localStorage.getItem('trial')) || {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getStoreTrialInfo = () => {
  const trialInfo = {};

  // Update property in user
  Object.keys(trialDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    trialInfo[key] = trialInfoLocalStorage[key]
      ? trialInfoLocalStorage[key]
      : trialDefaults[key];
  });

  // Include properties from localStorage
  Object.keys(trialInfoLocalStorage).forEach((key) => {
    if (trialInfo[key] === undefined && trialInfoLocalStorage[key] !== null)
      trialInfo[key] = trialInfoLocalStorage[key];
  });

  return trialInfo;
};

const fundingInfoLocalStorage =
  JSON.parse(localStorage.getItem('funding')) || {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getStoreFundingInfo = () => {
  const fundingInfo = {};

  // Update property in user
  Object.keys(fundingDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    fundingInfo[key] = fundingInfoLocalStorage[key]
      ? fundingInfoLocalStorage[key]
      : fundingDefaults[key];
  });

  // Include properties from localStorage
  Object.keys(trialInfoLocalStorage).forEach((key) => {
    if (fundingInfo[key] === undefined && fundingInfoLocalStorage[key] !== null)
      fundingInfo[key] = fundingInfoLocalStorage[key];
  });

  return fundingInfo;
};

const downloadInfoLocalStorage = localStorage.getItem('downloadInfo') || {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getDownloadInfo = () => {
  const downloadInfo = {};

  // Update property in user
  Object.keys(downloadDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    downloadInfo[key] = downloadInfoLocalStorage[key]
      ? downloadInfoLocalStorage[key]
      : downloadDefaults[key];
  });

  // Include properties from localStorage
  Object.keys(downloadInfoLocalStorage).forEach((key) => {
    if (
      downloadInfo[key] === undefined &&
      downloadInfoLocalStorage[key] !== null
    )
      downloadInfo[key] = downloadInfoLocalStorage[key];
  });

  return downloadInfo;
};

const uploadInfoLocalStorage = localStorage.getItem('uploadInfo') || {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUploadInfo = () => {
  const uploadInfo = {};

  // Update property in user
  Object.keys(downloadDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    uploadInfo[key] = uploadInfoLocalStorage[key]
      ? uploadInfoLocalStorage[key]
      : uploadDefaults[key];
  });

  // Include properties from localStorage
  Object.keys(downloadInfoLocalStorage).forEach((key) => {
    if (uploadInfo[key] === undefined && uploadInfoLocalStorage[key] !== null)
      uploadInfo[key] = uploadInfoLocalStorage[key];
  });

  return uploadInfo;
};

const exportInfoLocalStorage = localStorage.getItem('exportInfo') || {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getExportInfo = () => {
  const exportInfo = {};

  // Update property in user
  Object.keys(downloadDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    exportInfo[key] = exportInfoLocalStorage[key]
      ? exportInfoLocalStorage[key]
      : exportDefaults[key];
  });

  // Include properties from localStorage
  Object.keys(downloadInfoLocalStorage).forEach((key) => {
    if (exportInfo[key] === undefined && exportInfoLocalStorage[key] !== null)
      exportInfo[key] = exportInfoLocalStorage[key];
  });

  return exportInfo;
};

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    ''
  );
  return mq(query);
};

/**
 * Checks display banner is active or not
 * @param {Boolean} [isEnabled] should be set true if banner needs to be shown
 * @returns
 */
const checkBannerStatus = (isEnabled = false) => {
  if (!isEnabled) {
    localStorage.removeItem('isBannerShown');
    return true;
  }

  const isBannerShown = localStorage.getItem('isBannerShown');

  if (isBannerShown === 'true') return true;

  return false;
};
// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser: getUserInfo(),
  AppActiveStoreTrial: getStoreTrialInfo(),
  AppActiveStoreFunding: getStoreFundingInfo(),
  AppActiveDownload: getDownloadInfo(),
  AppActiveUpload: getUploadInfo(),
  AppActiveExport: getExportInfo(),
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  is_touch_device: is_touch_device(),
  mainLayoutType: themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList['pages'].data.filter(
    (page) => page.is_bookmarked
  ),
  theme: themeConfig.theme || 'light',
  themePrimaryColor: colors.primary,
  // if we show banner, change this value : true
  // if we don't show banner, change this value: false
  isBannerShown: checkBannerStatus(false),

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
};

export default state;
