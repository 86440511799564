import api from '@/api';

export default {
  /**
   * Action to set selected store for all forms that have Select Store element
   * @param {Object} vuex - vuex store instance
   * @param {Function} vuex.commit - vuex commit function
   * @param {Object} store - store to be selected
   */
  setSelectedStore({ commit }, store) {
    commit('SET_STORE', store);
  },

  setSelectedWarehouse({ commit }, warehouse) {
    commit('SET_WAREHOUSE', warehouse);
  },
  /**
   * Fetches stores list from api
   * @param {Object} vuex - vuex store instance
   */
  getStoresList({ commit }) {
    commit('SET_STORE_LIST_STATUS', { status: 'PENDING' });

    api.bearer
      .post({ status: 1 }, 'supply-chain/3pl/store/get-list')
      .then((response) => {
        if (!response) return;

        const { ApiStatusCode, ApiStatusMessage, Data } = response.data;

        if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
        commit('SET_STORE_LIST', Data);
        commit('SET_STORE_LIST_STATUS', { status: 'SUCCESS' });
        let oldStore = localStorage.getItem('store');
        oldStore =
          oldStore && oldStore != 'undefined' ? JSON.parse(oldStore) : null;

        oldStore = oldStore
          ? Data.find(
              (store) =>
                store.sellerId === oldStore.sellerId &&
                store.marketplaceId === oldStore.marketplaceId
            )
          : null;

        commit('SET_STORE', oldStore || Data[0]);
      })
      .catch((_error) => {
        commit('SET_STORE_LIST');
        commit('SET_STORE_LIST_STATUS', {
          status: 'ERROR',
          message: 'Store info could not be fetched...',
        });
      });
  },
  fetchLookupValues({ commit }) {
    commit('SET_LOOKUP_LISTS_STATUS', { status: 'PENDING' });
    api.bearer
      .post({}, 'supply-chain/3pl/look-up/get-values')
      .then((response) => {
        const { ApiStatus, ApiStatusMessage, Data } = response.data;

        if (!ApiStatus) throw new Error(ApiStatusMessage);

        Data.itemConditionList = Data.itemConditionList.sort(function (a, b) {
          return a.conditionCode - b.conditionCode;
        });
        const modifiedData = {
          ...Data,
          skuTypeList: [
            { label: 'Base', value: 1 },
            { label: 'Pack', value: 2 },
            { label: 'Bundle', value: 3 },
            { label: 'Each', value: 4 },
          ],
          productMappingTypeList: [
            {
              label: 'Mapped',
              value: 1,
            },
            {
              label: 'Unmapped',
              value: 2,
            },
          ],
          inventoryStatusTypeList: [
            {
              label: 'In stock',
              value: 'InStock',
            },
            {
              label: 'No stock',
              value: 'NoStock',
            },
          ],
        };

        commit('SET_LOOKUP_LISTS', modifiedData);
        commit('SET_LOOKUP_LISTS_STATUS', { status: 'SUCCESS' });
      })
      .catch((_error) => {
        commit('SET_LOOKUP_LISTS');
        commit('SET_LOOKUP_LISTS_STATUS', {
          status: 'ERROR',
          message: 'Lookup lists could not be fetched...',
        });
      });
  },
  clearStatuses({ commit }) {
    commit('SET_STORE_LIST_STATUS');
    commit('SET_LOOKUP_LISTS_STATUS');
  },
  setUserStore({ commit }, store) {
    commit('SET_USER_STORE', store);
  },
};
