import api from '@/api';

export default {
  requestSyncSKUs({ commit }, payload) {
    const storeCode = payload.storeId;
    const tenantId = payload.tenantId;

    if (!storeCode || !tenantId) {
      commit('SET_SKU_SYNCING_REQUEST_STATUS', {
        status: 'ERROR',
        message: 'Something wrong with store, please try again later...',
      });

      return;
    }

    commit('SET_SKU_SYNCING_REQUEST_STATUS', { status: 'PENDING' });
    api.bearer
      .post(
        { storeCode, tenantId },
        'supply-chain/3pl/product/amazon-product-sync'
      )
      .then((response) => {
        const { ApiStatus, ApiStatusMessage } = response.data;

        if (!ApiStatus) throw new Error(ApiStatusMessage);

        commit('SET_SKU_SYNCING_STORE', payload);

        commit('SET_SKU_SYNCING_REQUEST_STATUS', {
          status: 'SUCCESS',
          message: 'Sync request has been received',
        });
      })
      .catch((_error) => {
        commit('SET_SKU_SYNCING_REQUEST_STATUS', {
          status: 'ERROR',
          message:
            'Something went wrong while requesting sync. Please try again later...',
        });
      });
  },
  checkSkuSyncing({ commit, getters: { skuSyncingStore } }) {
    commit('SET_SKU_SYNCING_STATUS', { status: 'PENDING' });

    api.bearer
      .post(
        {
          storeCode: skuSyncingStore.storeId,
          tenantId: skuSyncingStore.tenantId,
        },
        'supply-chain/3pl/product/get-amazon-product-sync-status'
      )
      .then((response) => {
        const { ApiStatus, ApiStatusMessage, Data } = response.data;

        if (!ApiStatus) throw new Error(ApiStatusMessage);

        if (Data.syncFinished) {
          commit('SET_SKU_SYNCING_STATUS', {
            status: 'SUCCESS',
            message: `Product has ben synced for  ${skuSyncingStore.storeName}`,
          });

          return;
        }
        commit('SET_SKU_SYNCING_STATUS', { status: 'AWAITING_RECALL' });
      })
      .catch((_error) => {
        commit('SET_SKU_SYNCING_STATUS', {
          status: 'PENDING',
          message:
            'Something went wrong while syncing the Products. Please try again later...',
        });
      });
  },
  clearSkuSyncing({ commit }) {
    commit('SET_SKU_SYNCING_STATUS');
    commit('SET_SKU_SYNCING_STORE');
  },
  clearSkuSyncingRequestStatus({ commit }) {
    commit('SET_SKU_SYNCING_REQUEST_STATUS');
  },
  requestSyncImageSKUs({ commit }, payload) {
    const storeCode = payload.storeId;
    const tenantId = payload.tenantId;

    if (!storeCode || !tenantId) {
      commit('SET_SKU_IMAGE_SYNCING_REQUEST_STATUS', {
        status: 'ERROR',
        message: 'Something wrong with store, please try again later...',
      });

      return;
    }

    commit('SET_SKU_IMAGE_SYNCING_REQUEST_STATUS', { status: 'PENDING' });
    api.bearer
      .post({ storeCode, tenantId }, 'supply-chain/3pl/product/image-sync')
      .then((response) => {
        const { ApiStatus, ApiStatusMessage } = response.data;

        if (!ApiStatus) throw new Error(ApiStatusMessage);

        commit('SET_SKU_IMAGE_SYNCING_STORE', payload);

        commit('SET_SKU_IMAGE_SYNCING_REQUEST_STATUS', {
          status: 'SUCCESS',
          message: 'Image sync request has been received',
        });
      })
      .catch((_error) => {
        commit('SET_SKU_IMAGE_SYNCING_REQUEST_STATUS', {
          status: 'ERROR',
          message:
            'Something went wrong while requesting Image sync. Please try again later...',
        });
      });
  },
  checkSkuImageSyncing({ commit, getters: { skuImageSyncingStore } }) {
    commit('SET_SKU_IMAGE_SYNCING_STATUS', { status: 'PENDING' });

    api.bearer
      .post(
        {
          storeCode: skuImageSyncingStore.storeId,
          tenantId: skuImageSyncingStore.tenantId,
        },
        'supply-chain/3pl/product/get-image-sync-status'
      )
      .then((response) => {
        const { ApiStatus, ApiStatusMessage, Data } = response.data;

        if (!ApiStatus) throw new Error(ApiStatusMessage);

        if (Data.syncFinished) {
          commit('SET_SKU_IMAGE_SYNCING_STATUS', {
            status: 'SUCCESS',
            message: `Images has ben synced for  ${skuImageSyncingStore.storeName}`,
          });

          return;
        }
        commit('SET_SKU_IMAGE_SYNCING_STATUS', { status: 'AWAITING_RECALL' });
      })
      .catch((_error) => {
        commit('SET_SKU_IMAGE_SYNCING_STATUS', {
          status: 'PENDING',
          message:
            'Something went wrong while syncing the Images. Please try again later...',
        });
      });
  },
  clearSkuImageSyncing({ commit }) {
    commit('SET_SKU_IMAGE_SYNCING_STATUS');
    commit('SET_SKU_IMAGE_SYNCING_STORE');
  },
  clearSkuImageSyncingRequestStatus({ commit }) {
    commit('SET_SKU_IMAGE_SYNCING_REQUEST_STATUS');
  },
};
