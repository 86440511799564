const exportJobTemplate = {
  taskId: null,
  url: null,
  status: 'IDLE',
  message: null,
};

export default {
  /**
   * Set taskId on designated export job
   * @param {Object} state - export module store
   * @param {Number} templateType - export job template type
   * @param {String} taskId - export job task ID
   */
  SET_TASK: (state, { templateType, taskId = null }) => {
    state.exportJobs[templateType].taskId = taskId;
  },
  /**
   * Set status on designated export job
   * @param {Object} state - export module store
   * @param {Number} templateType - export job template type
   * @param {Object} obj - payload
   * @param {String} [obj.status=IDLE] - store list api call status
   * @param {String} [obj.message] - store list api call message
   */
  SET_STATUS: (state, { templateType, status = 'IDLE', message = null }) => {
    state.exportJobs[templateType].status = status;
    state.exportJobs[templateType].message = message;
  },
  SET_URL: (state, { templateType, url }) => {
    state.exportJobs[templateType].url = url;
  },
  CLEAR_TASK: (state, templateType) => {
    state.exportJobs.splice(templateType, 1, {
      ...state.exportJobs[templateType],
      ...exportJobTemplate,
    });
  },
  /**
   * Clears all jobs
   * @param {Object} state - export module store
   */
  CLEAR_TASKS: (state) => {
    state.exportJobs = [
      { ...state.exportJobs[0], ...exportJobTemplate },
      { ...state.exportJobs[1], ...exportJobTemplate },
      { ...state.exportJobs[2], ...exportJobTemplate },
      { ...state.exportJobs[3], ...exportJobTemplate },
      { ...state.exportJobs[4], ...exportJobTemplate },
      { ...state.exportJobs[5], ...exportJobTemplate },
      { ...state.exportJobs[6], ...exportJobTemplate },
      { ...state.exportJobs[7], ...exportJobTemplate },
      { ...state.exportJobs[8], ...exportJobTemplate },
      { ...state.exportJobs[9], ...exportJobTemplate },
      { ...state.exportJobs[10], ...exportJobTemplate },
    ];
  },
};
