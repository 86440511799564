export default {
  /**
   * Handles saving store on vuex module
   * If store is selected to all, then nullifies the selected store value
   * @param {Object} state - form management module store
   * @param {Object} store - store to be saved
   */
  SET_STORE: (state, store) => {
    localStorage.setItem('store', JSON.stringify(store));
    state.selectedStore = store;
  },

  SET_WAREHOUSE: (state, warehouse) => {
    if (warehouse)
      state.selectedWarehouse =
        warehouse.warehouseName !== 'All' ? warehouse : null;
    else state.selectedWarehouse = null;
  },
  /**
   * Handles saving store list
   * @param {Object} state - form management module store
   * @param {Array} payload - store list
   */
  SET_STORE_LIST: (state, payload) => {
    state.storeList.list = payload;
  },
  /**
   * Handles saving store list api call status
   * @param {Object} state - form management module states
   * @param {Object} [obj] - payload
   * @param {String} [obj.status=IDLE] - store list api call status
   * @param {String} [obj.message] - store list api call message
   */
  SET_STORE_LIST_STATUS: (
    state,
    { status = 'IDLE', message = null } = { status: 'IDLE', message: null }
  ) => {
    state.storeList.status = status;
    state.storeList.message = message;
  },
  SET_LOOKUP_LISTS: (
    state,
    payload = {
      warehouseList: [],
      shippingMethodList: [],
      carrierList: [],
      packageTypeList: [],
      itemConditionList: [],
      poIdentifierTypeList: [],
    }
  ) => {
    state.lookupValues.lists = payload;
  },
  /**
   * Handles saving lookup lists api call status
   * @param {Object} state - form management module states
   * @param {obj} [obj] - payload
   * @param {String} [obj.status=IDLE] - lookup lists api call status
   * @param {String} [obj.message] - lookup lists api call message
   */
  SET_LOOKUP_LISTS_STATUS: (
    state,
    { status = 'IDLE', message = null } = { status: 'IDLE', message: null }
  ) => {
    state.lookupValues.status = status;
    state.lookupValues.message = message;
  },
  SET_USER_STORE: (state, store) => {
    state.userStores = store;
  },
};
