import axios from '../../../axios/index.js';
import store from '../../../../store/store.js';

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function _addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init() {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const { response } = error;

        if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            store.dispatch('auth/fetchAccessToken').then((access_token) => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(access_token);
            });
          }
        }
        return Promise.reject(error);
      }
    );
  },
  login(email, pwd) {
    const baseURL = process.env.VUE_APP_SERVICE_URL;
    const clientId = process.env.VUE_APP_CLIENT_ID;
    const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
    return axios({
      url: `${baseURL}oauth/token`,
      method: 'post',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Methods': 'POST',
      },
      data: {
        Email: email,
        Password: pwd,
        GrantType: 'password',
        Scope: 'amazon_data',
        ClientId: clientId,
        ClientSecret: clientSecret,
        RedirectUri: 'https://api.eva.guru',
      },
    });
  },

  registerUser(name, email, pwd) {
    return axios.post('/api/auth/register', {
      displayName: name,
      email,
      password: pwd,
    });
  },
  refreshToken() {
    return axios.post('/api/auth/refresh-token', {
      accessToken: localStorage.getItem('accessToKen'),
    });
  },
};
