import state from './moduleExportState.js';
import mutations from './moduleExportMutations.js';
import actions from './moduleExportActions.js';
import getters from './moduleExportGetters.js';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
